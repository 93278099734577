export const header = "#000000";
// export const navbar = "#000000";
// export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#ffcd24";
export const dimBackground = "#e6e3e3";
export const lightBackground = "#000000";
export const darkBackground = "#303030";
export const white = "#ffffff";

//theme color for Brand Invoice
export const brandName = "Premium Academics Help";

export const navbar = "#ffffff";
export const navbarText = "#000000";
export const lightText = "#ffffff";
export const mainBackgound = "#000000";
export const invoiceCard = "#f0f0f0";
export const button = "#ffbc0b";
export const cardBackground = "#e1e2e6";

